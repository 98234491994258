/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// // react-router-dom components
// import { Link } from "react-router-dom";

// // @mui material components
// import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// // @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";

// // Authentication layout components
// import BasicLayout from "layouts/authentication/components/BasicLayout";

// // Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// function Basic() {
//   const [rememberMe, setRememberMe] = useState(false);

//   const handleSetRememberMe = () => setRememberMe(!rememberMe);

//   return (
//     <Card>
//       <MDBox
//         variant="gradient"
//         bgColor="info"
//         borderRadius="lg"
//         coloredShadow="info"
//         mx={2}
//         mt={-3}
//         p={2}
//         mb={1}
//         textAlign="center"
//       >
//         <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
//           Sign in
//         </MDTypography>
//         {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
//           <Grid item xs={2}>
//             <MDTypography component={MuiLink} href="#" variant="body1" color="white">
//               <FacebookIcon color="inherit" />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={2}>
//             <MDTypography component={MuiLink} href="#" variant="body1" color="white">
//               <GitHubIcon color="inherit" />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={2}>
//             <MDTypography component={MuiLink} href="#" variant="body1" color="white">
//               <GoogleIcon color="inherit" />
//             </MDTypography>
//           </Grid>
//         </Grid> */}
//       </MDBox>
//       <MDBox pt={4} pb={3} px={3}>
//         <MDBox component="form" role="form">
//           <MDBox mb={2}>
//             <MDInput type="email" label="Email" fullWidth />
//           </MDBox>
//           <MDBox mb={2}>
//             <MDInput type="password" label="Password" fullWidth />
//           </MDBox>
//           <MDBox display="flex" alignItems="center" ml={-1}>
//             <Switch checked={rememberMe} onChange={handleSetRememberMe} />
//             <MDTypography
//               variant="button"
//               fontWeight="regular"
//               color="text"
//               onClick={handleSetRememberMe}
//               sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
//             >
//               &nbsp;&nbsp;Remember me
//             </MDTypography>
//           </MDBox>
//           <MDBox mt={4} mb={1}>
//             <MDButton variant="gradient" color="info" fullWidth>
//               sign in
//             </MDButton>
//           </MDBox>
//           <MDBox mt={3} mb={1} textAlign="center">
//             <MDTypography variant="button" color="text">
//               Don&apos;t have an account?{" "}
//               <MDTypography
//                 component={Link}
//                 to="/authentication/sign-up"
//                 variant="button"
//                 color="info"
//                 fontWeight="medium"
//                 textGradient
//               >
//                 Sign up
//               </MDTypography>
//             </MDTypography>
//           </MDBox>
//         </MDBox>
//       </MDBox>
//     </Card>
//   );
// }

// export default Basic;
import { useState, useEffect } from "react";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider, makeStyles } from "@mui/material/styles";
import {} from "assets/css/style.css";
import stall from "assets/images/photo-1.jpg";
import { ORDERTAKING, Common_variable, BOXING, api } from "../../../utility/constants";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setLayout } from "context";
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Basic() {
  // const classes = useStyles();
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [btnOtpDisabled, setBtnOtpDisabled] = useState(false);
  const [btnsigninDisabled, setBtnSigninDisabled] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [OTP_Error, setOTP_Error] = useState("");
  const loginAPI = async () => {
    const params = { mobilenumber: mobileNumber, otp: Number(otp) };
    const response = await axios.post(`${api.api_url}/loginRoutes/loginJwt`, params);
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(`${api.api_url}/loginRoutes/login`, param);
      if (response1 && response1.data && response1.data.body) {
        const loginresponse = response1.data.body;
        const userData = loginresponse.UserInfo ? loginresponse.UserInfo[0] : [];
        console.log(userData, "userData");
        if (loginresponse && loginresponse.statusFlag === 1) {
          toast.success(loginresponse.message, {
            duration: 2000,
            className: "toaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#2e7d32" },
          });
          console.log(loginresponse.UserInfo[0].edit_access, "loginresponse");
          localStorage.setItem("userCode", loginresponse.UserInfo[0].uid);
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("edit_access", loginresponse.UserInfo[0].edit_access);
          navigate("/stall");
        } else {
          toast.error(loginresponse.message, {
            duration: 2000,
            className: "waringtoaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#ed6c02" },
          });
        }
      }
    }
  };
  const getOTPAPI = async () => {
    const params = { mobilenumber: mobileNumber };
    setBtnOtpDisabled(true);
    const response = await axios.post(`${api.api_url}/loginRoutes/getotpJwt`, params);
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(`${api.api_url}/loginRoutes/getotp`, param);
      if (response1 && response1.data && response1.data.body) {
        const loginresponse = response1.data.body;
        const userData = loginresponse.UserInfo ? loginresponse.UserInfo[0] : [];
        if (loginresponse && loginresponse.statusFlag === 1) {
          toast.success(loginresponse.message, {
            duration: 2000,
            className: "toaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#2e7d32" },
          });
        } else {
          toast.error(loginresponse.message, {
            duration: 2000,
            className: "waringtoaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#ed6c02" },
          });
        }
      }
    }
  };
  const signIn = async () => {
    if (mobileNumber === "" && otp === "") {
      setMobileError(true);
      setMobileNumberError(Common_variable.MOBILENOERROR);
      setOtpError(true);
      setOTP_Error(Common_variable.OTPERROR);
    } else if (mobileNumber === undefined || mobileNumber === "") {
      setMobileError(true);
      setMobileNumberError(Common_variable.MOBILENOERROR);
    } else if (otp === undefined || otp === "") {
      setOtpError(true);
      setOTP_Error(Common_variable.OTPERROR);
    } else {
      if (mobileNumber.length < 10) {
        setMobileError(true);
        setMobileNumberError(Common_variable.MOBILENOLENGTHERROR);
      } else if (otp.length < 6) {
        setMobileError(true);
        setMobileNumberError(Common_variable.OTPLENGTHERROR);
      } else {
        setMobileError(false);
        setMobileNumberError("");
        setOtpError(false);
        setOTP_Error("");
        await loginAPI();
      }
    }
  };
  const getOTP = async () => {
    if (mobileNumber === "" || mobileNumber === undefined || mobileNumber === "") {
      setMobileError(true);
      setMobileNumberError(Common_variable.MOBILENOERROR);
    } else if (mobileNumber.length < 10) {
      setMobileError(true);
      setMobileNumberError(Common_variable.MOBILENOLENGTHERROR);
    } else {
      setMobileError(false);
      setMobileNumberError("");
      await getOTPAPI();
    }
  };
  const onBlurEvent = (e, from) => {
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      if (from == "mobile") {
        setMobileError(true);
        setMobileNumberError(Common_variable.MOBILENOERROR);
      } else if (from == "otp") {
        setOtpError(true);
        setOTP_Error(Common_variable.OTPERROR);
      }
    } else {
      if (from == "mobile") {
        setMobileError(false);
        setMobileNumberError("");
      } else if (from == "otp") {
        setOtpError(false);
        setOTP_Error("");
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };
  useEffect(() => {
    setLayout(dispatch, "login");
    setBtnSigninDisabled(false);
    setBtnOtpDisabled(false);
    setOTP("");
    setMobileNumber("");
  }, []);
  const numberOnly = (event) => {
    const regex = /^[0-9]*$/;
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const onChangeEvent = (e, from) => {
    if (from == "mobile") {
      setMobileNumber(e.target.value);
      if (e.target.value.length < 10) {
        setMobileError(true);
        setMobileNumberError(Common_variable.MOBILENOLENGTHERROR);
      } else {
        setMobileError(false);
        setMobileNumberError("");
      }
    } else if (from == "otp") {
      setOTP(e.target.value);
      if (e.target.value.length < 6) {
        setOtpError(true);
        setOTP_Error(Common_variable.OTPLENGTHERROR);
      } else {
        setOtpError(false);
        setOTP_Error("");
      }
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${stall})`,
            // backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Stall Booking
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="mobileno"
                label="Mobile Number"
                name="mobileno"
                autoFocus
                value={mobileNumber}
                inputProps={{ maxLength: 10, minLength: 10 }}
                variant="outlined"
                onChange={(e) => onChangeEvent(e, "mobile")}
                onBlur={(event) => onBlurEvent(event, "mobile")}
                onKeyPress={(event) => numberOnly(event)}
                // className={classes.root}
              />
              <Box component="form" sx={{ mt: 1 }}>
                <span className="invalid-feedback">
                  {mobileError === true ? mobileNumberError : ""}
                  {/* {mobileNumberError} */}
                </span>
              </Box>
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    type="text"
                    id="otp"
                    value={otp}
                    // maxLength="6"
                    // minLength="6"
                    onChange={(e) => onChangeEvent(e, "otp")}
                    onBlur={(event) => onBlurEvent(event, "otp")}
                    onKeyPress={(event) => numberOnly(event)}
                    inputProps={{ maxLength: 6 }}
                    variant="outlined"
                  />
                  <Box component="form" sx={{ mt: 1 }}>
                    <span className="invalid-feedback">{otpError === true ? OTP_Error : ""}</span>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    disabled={btnOtpDisabled}
                    type="button"
                    className="marginlf20"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => getOTP()}
                  >
                    Get OTP
                  </Button>
                </Grid>
              </Grid>
              <Button
                disabled={btnsigninDisabled}
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => signIn()}
              >
                Sign In
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Toaster position={"top-right"} toastOptions={{ className: "react-hot-toast" }} />
    </ThemeProvider>
  );
}
