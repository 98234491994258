// @mui material components
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  FormFeedback,
  Button,
} from "reactstrap";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Select from "react-select";
import axios from "axios";
import { ORDERTAKING, Common_variable, BOXING, api } from "../../utility/constants";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { forwardRef } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// ** Styles Imports
// import '@core/scss/react/libs/react-select/_react-select.scss'
// import '@core/scss/react/libs/flatpickr/flatpickr.scss'
// import '../../@core/scss/react/libs/react-select/_react-select.scss'
// ** Third Party Components
import {
  Eye,
  Send,
  Edit,
  Copy,
  Save,
  Info,
  Trash,
  PieChart,
  Download,
  TrendingUp,
  CheckCircle,
  MoreVertical,
  ArrowDownCircle,
  ChevronDown,
} from "react-feather";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "`Segoe UI`",
      "Roboto",
      "`Helvetica Neue`",
      "Arial",
      "sans-serif",
      "`Apple Color Emoji`",
      "`Segoe UI Emoji`",
      "`Segoe UI Symbol`",
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
function Sponsor() {
  const [userid, setUserID] = useState(0);
  const [usereditoption, setUserEditOption] = useState(false);
  const [sponsorJson, setSponsorJson] = useState({});
  const [btnBookDisabled, setBtnBookDisabled] = useState(false);
  const [btnCloseDisabled, setBtnCloseDisabled] = useState(false);
  const [btnCancelDisabled, setBtnCancelDisabled] = useState(false);
  const [btnConfirmYesDisabled, setBtnConfirmYesDisabled] = useState(false);
  const [btnConfirmNoDisabled, setBtnConfirmNoDisabled] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [price, setPrice] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [sponsorID, setsponsorID] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyName_Error, setCompanyName_Error] = useState("");
  const [contactPersonNameError, setContactPersonNameError] = useState(false);
  const [contactPersonName_Error, setContactPersonName_Error] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [cityError, setCityError] = useState(false);
  const [city_Error, setCity_Error] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState(false);
  const [companyAddress_Error, setCompanyAddress_Error] = useState("");
  const [stateError, setStateError] = useState(false);
  const [state_Error, setState_Error] = useState("");
  const [sponsorError, setSponsorError] = useState(false);
  const [sponsor_Error, setSponsor_Error] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [price_Error, setPrice_Error] = useState("");
  const handleOpen = () => setSponsorOpen(true);
  const handleClose = () => setSponsorOpen(false);
  const refCompanyname = useRef(null);
  const textcompanyNameRef = useRef(null);
  const textcontactPersonNameRef = useRef(null);
  const textMobileNumberRef = useRef(null);
  const textcompanyAddressRef = useRef(null);
  const textpriceRef = useRef(null);
  const sponsorshipRef = useRef(null);
  const textCityRef = useRef(null);
  const textStateRef = useRef(null);
  const [sponsorOpen, setSponsorOpen] = useState(false);
  const [btnTitle, setBtnTitle] = useState("Save");
  const [BindSponsorshipCatList, setBindSponsorshipCatList] = useState([]);
  const [BindSponsorshipId, setBindSponsorshipId] = useState(null);
  const [selectedSponsorshipId, setSelectedSponsorshipId] = useState(0);
  const [sponsorShipList, setSponsorShipList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [empRecords, setempRecords] = useState(null);
  const [selectOptions, setselectOptions] = useState(null);
  const [companyNameDisabled, setCompanyNameDisabled] = useState(false);
  const [contactPersonNameDisabled, setContactPersonNameDisabled] = useState(false);
  const [mobileNumberDisabled, setMobileNumberDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(false);
  const [companyAddressDisabled, setCompanyAddressDisabled] = useState(false);
  const [sponsorshipDisabled, setSponsorshipDisabled] = useState(false);
  const [priceDisabled, setPriceDisabled] = useState(false);
  const [age, setAge] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  // let empRecords = "";
  //existing pagination
  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const handleSort = (column, sortDirection) => {
    const sortable = column.sortField;
    const colorList = sponsorShipList.sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortable].toLowerCase() < b[sortable].toLowerCase() ? 1 : -1;
      } else if (sortDirection === "desc") {
        return a[sortable].toLowerCase() < b[sortable].toLowerCase() ? -1 : 1;
      }
      setSponsorShipList(colorList);
    });
  };
  //Edit Click
  const editSponsor = (data) => {
    localStorage.setItem("btnDisabled", "true");
    setSponsorJson({});
    clearError();
    setCompanyName(data.companyname);
    setContactPersonName(data.contactperson);
    setMobileNumber(data.mobilenumber);
    setCompanyAddress(data.companyaddress);
    setCity(data.city);
    setState(data.state);
    setsponsorID(data.sponsorid);
    setPrice(data.price);
    setSelectedSponsorshipId(data.sponsorshipid);
    BindSponsorshipCatList.map((e) => {
      if (e.value == data.sponsorshipid) {
        var obj = {
          label: e.label,
          value: data.sponsorshipid,
        };
        setBindSponsorshipId(obj);
      }
    });
    if (usereditoption == false) {
      setCompanyNameDisabled(true);
      setContactPersonNameDisabled(true);
      setMobileNumberDisabled(true);
      setStateDisabled(true);
      setCityDisabled(true);
      setCompanyAddressDisabled(true);
      setSponsorshipDisabled(true);
      setPriceDisabled(true);
      setBtnCancelDisabled(true);
      setBtnBookDisabled(true);
    }
    setBtnTitle("Update");
    setSponsorJson(data);
    setSponsorOpen(true);
  };
  //table cloums
  const columns = [
    {
      name: "S.No.",
      maxWidth: "10px",
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "Company Details",
      minWidth: "100px",
      cell: (row) => {
        return (
          // <div className="justify-content-left align-items-center paddingtop-1">
          //   <h6 className="user-name text-truncate mb-0 wraptext vertical_align">
          //     {row.companyname}
          //   </h6>
          // </div>
          <div className="justify-content-left align-items-center paddingtop-1">
            {row.companyname}
          </div>
        );
      },
    },
    {
      name: "Sponsorship",
      minWidth: "100px",
      cell: (row) => {
        return (
          // <div className="justify-content-left align-items-center paddingtop-1">
          //   {row.sponsorname ? (
          //     <pre className="wraptextpre vertical_align  mb-0">
          //       <span className="user-name text-truncate mb-0 wraptextpre vertical_align">
          //         {row.sponsorname}
          //       </span>
          //     </pre>
          //   ) : (
          //     ""
          //   )}
          // </div>
          <div className="justify-content-left align-items-center paddingtop-1">
            {row.sponsorname}
          </div>
        );
      },
    },
    {
      name: "Price",
      minWidth: "100px",
      cell: (row) => {
        return (
          <div className="justify-content-left align-items-center paddingtop-1">
            ₹ {row.price}
            {/* <Badge color="light-success">
              <AiOutlineUser size={12} className="align-middle me-25" />
              {row.user_name}
            </Badge> */}
          </div>
        );
      },
    },
    {
      name: "Action",
      minWidth: "90px",
      center: true,
      cell: (row) => (
        <div className="column-action d-flex align-items-center">
          <div>
            <Edit
              size={14}
              className="me-50 cursor-focus"
              id={`edit-tooltip-${row.id}`}
              onClick={() => editSponsor(row)}
            />
            <UncontrolledTooltip placement="top" target={`edit-tooltip-${row.id}`}>
              Edit
            </UncontrolledTooltip>
          </div>
          {/* {row.default_column === false ?   <Trash size={14} className="me-50 cursor-focus" id={`delete-tooltip-${row.id}`} onClick={() => deleteAgent(row)} /> : ""}
          {row.default_column === false ? <UncontrolledTooltip placement="top" target={`delete-tooltip-${row.id}`}>
            Delete
          </UncontrolledTooltip> : ""} */}
        </div>
      ),
    },
    // <Edit size={14} className="me-50 cursor-focus" id={`edit-tooltip-${row.id}`} onClick={() => editAgent(row)} />
  ];
  // //custom pagination
  const CustomPagination = () => {
    let count = 0;
    if (sponsorShipList.length > 0) {
      count = Number(Math.ceil(sponsorShipList.length / rowsPerPage));
    } else {
      count = 1;
    }
    return (
      <ReactPaginate
        nextLabel=""
        breakLabel="..."
        previousLabel=""
        pageCount={count || 1}
        activeClassName="active"
        breakClassName="page-item"
        pageClassName={"page-item"}
        breakLinkClassName="page-link"
        nextLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousLinkClassName={"page-link"}
        previousClassName={"page-item prev"}
        onPageChange={(page) => handlePagination(page)}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName={"pagination react-paginate justify-content-end p-1"}
      />
    );
  };
  const itemOnchange = (e) => {
    if (e && e.length > 0 && e.filter((s) => s.value.toString() === "0").length > 0) {
      setBindSponsorshipId(null);
      setBindSponsorshipId(e.filter((s) => s.value.toString() === "0"));
    } else {
      setBindSponsorshipId(e);
    }
    setSelectedSponsorshipId(e.value);
  };
  const clearError = () => {
    setCompanyNameError(false);
    setCompanyName_Error("");
    setContactPersonNameError(false);
    setContactPersonName_Error("");
    setMobileError(false);
    setMobileNumberError("");
    setCompanyAddressError(false);
    setCompanyAddress_Error("");
    setCityError(false);
    setCity_Error("");
    setStateError(false);
    setState_Error("");
    setSponsor_Error("");
    setSponsorError(false);
    setPrice_Error("");
    setPriceError(false);
  };
  const clearPopupData = () => {
    setCompanyName("");
    setContactPersonName("");
    setMobileNumber("");
    setCompanyAddress("");
    setCity("");
    setState("");
    setPrice("");
    setBindSponsorshipId(null);
    setBtnBookDisabled(false);
    setBtnCloseDisabled(false);
    setBtnCancelDisabled(false);
  };
  const getSponsorshipList = async () => {
    const params = { userid: Number(userid) };
    const response = await axios.post(`${api.api_url}/sponsorRoutes/getsponsorJwt`, params);
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(`${api.api_url}/sponsorRoutes/getsponsor`, param);
      if (response1 && response1.data && response1.data.body) {
        const sponsorresponse = response1.data.body;
        const sponsorshipcategoryList = sponsorresponse.sponsorshipcategoryList
          ? sponsorresponse.sponsorshipcategoryList
          : [];
        const sponsor_List = sponsorresponse.sponsorList ? sponsorresponse.sponsorList : [];
        setSponsorShipList(sponsor_List);
        setBindSponsorshipCatList(sponsorshipcategoryList);
        // setSponsorShipList();
        // var array = stallListData.map((e) => {
        //   e.status = "Pending";
        //   return e;
        // });
      }
    }
  };
  useEffect(() => {
    var user_id = localStorage.getItem("userCode");
    if (
      user_id == "" ||
      user_id == undefined ||
      user_id == null ||
      user_id == "undefined" ||
      user_id == "null"
    ) {
      navigate("/login");
    }
    var editaccess = localStorage.getItem("edit_access");
    if (
      editaccess == "" ||
      editaccess == undefined ||
      editaccess == null ||
      editaccess == "undefined" ||
      editaccess == "null" ||
      editaccess == "no"
    ) {
      setUserEditOption(false);
    } else {
      setUserEditOption(true);
    }
    setUserID(user_id);
    setSponsorOpen(false);
    setSponsorShipList([]);
    setCurrentPage(1);
    setRowsPerPage(10);
    setBindSponsorshipCatList([]);
    getSponsorshipList();
  }, []);
  useEffect(() => {
    let emp =
      BindSponsorshipCatList.length > 0 &&
      BindSponsorshipCatList.map((data) => {
        return (
          <option value={data.value} key={data.value}>
            {data.label}
          </option>
        );
      });
    let emp1 =
      BindSponsorshipCatList.length > 0 &&
      BindSponsorshipCatList.map((data) => {
        return (
          <MenuItem value={data.value} key={data.value}>
            {data.label}
          </MenuItem>
          // <option value={data.value} key={data.value}>
          //   {data.label}
          // </option>
        );
      });
    setempRecords(emp);
    setselectOptions(emp1);
  }, [BindSponsorshipCatList]);
  const saveSponsor = async () => {
    const params = {
      sponsorID: sponsorID,
      companyName: companyName,
      contactPersonName: contactPersonName,
      mobileNumber: mobileNumber,
      companyAddress: companyAddress,
      city: city,
      state: state,
      userid: Number(userid),
      sponsorshipId: Number(selectedSponsorshipId),
      price: Number(price),
    };
    const response = await axios.post(`${api.api_url}/sponsorRoutes/saveSponsorJwt`, params);
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(`${api.api_url}/sponsorRoutes/saveSponsor`, param);
      if (response1 && response1.data && response1.data.body) {
        const sponsorResponse = response1.data.body;
        if (sponsorResponse && sponsorResponse.statusFlag === 1) {
          toast.success(sponsorResponse.message, {
            duration: 2000,
            className: "toaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#2e7d32" },
          });
          setSponsorOpen(false);
          localStorage.removeItem("btnDisabled");
          clearError();
          setBtnTitle("Save");
          setSponsorJson({});
          clearPopupData();
          setSponsorShipList([]);
          setBindSponsorshipCatList([]);
          getSponsorshipList();
        } else {
          toast.error(sponsorResponse.message, {
            duration: 2000,
            className: "waringtoaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#ed6c02" },
          });
          setBtnBookDisabled(false);
          setBtnCloseDisabled(false);
          setBtnCancelDisabled(false);
        }
      }
    }
  };
  const beforeSaveSponsor = async () => {
    if (companyName == "" || companyName == undefined) {
      setCompanyNameError(true);
      setCompanyName_Error(Common_variable.COMPANYNAMEERROR);
      // if (textcompanyNameRef.current) {
      //   textcompanyNameRef.current.focus();
      // }
    } else if (contactPersonName == "" || contactPersonName == undefined) {
      setContactPersonNameError(true);
      setContactPersonName_Error(Common_variable.CONTACTPERSONNAMEERROR);
      // if (textcontactPersonNameRef.current) {
      //   textcontactPersonNameRef.current.focus();
      // }
    } else if (mobileNumber == "" || mobileNumber == undefined) {
      setMobileError(true);
      setMobileNumberError(Common_variable.MOBILENOERROR);
      // if (textMobileNumberRef.current) {
      //   textMobileNumberRef.current.focus();
      // }
    } else if (companyAddress == "" || companyAddress == undefined) {
      setCompanyAddressError(true);
      setCompanyAddress_Error(Common_variable.COMPANYADDRESSERROR);
      // if (textcompanyAddressRef.current) {
      //   textcompanyAddressRef.current.focus();
      // }
    } else if (city == "" || city == undefined) {
      setCityError(true);
      setCity_Error(Common_variable.CITYERROR);
      // if (textCityRef.current) {
      //   textCityRef.current.focus();
      // }
    } else if (state == "" || state == undefined) {
      setStateError(true);
      setState_Error(Common_variable.STATEERROR);
      // if (textStateRef.current) {
      //   textStateRef.current.focus();
      // }
    } else if (city == "" || city == undefined) {
      setSponsorError(true);
      setSponsor_Error(Common_variable.SPONSORSHIPERROR);
      // if (sponsorshipRef.current) {
      //   sponsorshipRef.current.focus();
      // }
    } else if (price == "" || price == undefined) {
      setPriceError(true);
      setPrice_Error(Common_variable.PRICEERROR);
      // if (textpriceRef.current) {
      //   textpriceRef.current.focus();
      // }
    } else {
      setBtnBookDisabled(true);
      setBtnCloseDisabled(true);
      setBtnCancelDisabled(true);
      clearError();
      await saveSponsor();
    }
  };
  const numberOnly = (event) => {
    const regex = /^[0-9]*$/;
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const onEnterKeyPressed = (e) => {
    if (e.key === "Enter") {
      if (usereditoption == true) {
        beforeSaveSponsor();
      }
    }
  };
  const addSponsor = (t, from) => {
    localStorage.setItem("btnDisabled", "true");
    setSponsorJson({});
    clearError();
    if (t == "") {
      clearPopupData();
      //   setsponsorID(t.sponsorID);
      setCompanyNameDisabled(false);
      setContactPersonNameDisabled(false);
      setMobileNumberDisabled(false);
      setStateDisabled(false);
      setCityDisabled(false);
      setCompanyAddressDisabled(false);
      setSponsorshipDisabled(false);
      setPriceDisabled(false);
      setBtnCancelDisabled(false);
      setBtnBookDisabled(false);
      setBtnTitle("Save");
    } else {
      setCompanyName(t.companyname);
      setContactPersonName(t.contactpersonname);
      setMobileNumber(t.mobilenumber);
      setCompanyAddress(t.companyaddress);
      setCity(t.cityname);
      setState(t.statename);
      setsponsorID(t.sponsorID);
      setBtnTitle("Update");
      if (usereditoption == false) {
        setCompanyNameDisabled(true);
        setContactPersonNameDisabled(true);
        setMobileNumberDisabled(true);
        setStateDisabled(true);
        setCityDisabled(true);
        setCompanyAddressDisabled(true);
        setSponsorshipDisabled(true);
        setPriceDisabled(true);
        setBtnCancelDisabled(true);
        setBtnBookDisabled(true);
      }
    }
    setSponsorJson(t);
    setSponsorOpen(true);
  };
  const onChangeEvent = (e, from) => {
    if (from == "companyname") {
      setCompanyName(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setCompanyNameError(true);
        setCompanyName_Error(Common_variable.COMPANYNAMEERROR);
      } else {
        setCompanyNameError(false);
        setCompanyName_Error("");
      }
    } else if (from == "contactpersonname") {
      var ch = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setContactPersonName(ch);
      if (e.target.value == "" || e.target.value == undefined) {
        setContactPersonNameError(true);
        setContactPersonName_Error(Common_variable.CONTACTPERSONNAMEERROR);
      } else {
        setContactPersonNameError(false);
        setContactPersonName_Error("");
      }
    } else if (from == "mobileno") {
      setMobileNumber(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setMobileError(true);
        setMobileNumberError(Common_variable.MOBILENOERROR);
      } else {
        if (e.target.value.length < 10) {
          setMobileError(true);
          setMobileNumberError(Common_variable.MOBILENOLENGTHERROR);
        } else {
          setMobileError(false);
          setMobileNumberError("");
        }
      }
    } else if (from == "companyaddress") {
      setCompanyAddress(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setCompanyAddressError(true);
        setCompanyAddress_Error(Common_variable.COMPANYADDRESSERROR);
      } else {
        setCompanyAddressError(false);
        setCompanyAddress_Error("");
      }
    } else if (from == "city") {
      var ch = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setCity(ch);
      if (e.target.value == "" || e.target.value == undefined) {
        setCityError(true);
        setCity_Error(Common_variable.CITYERROR);
      } else {
        setCityError(false);
        setCity_Error("");
      }
    } else if (from == "state") {
      var ch = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setState(ch);
      if (e.target.value == "" || e.target.value == undefined) {
        setStateError(true);
        setState_Error(Common_variable.STATEERROR);
      } else {
        setStateError(false);
        setState_Error("");
      }
    } else if (from == "price") {
      setPrice(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setStateError(true);
        setState_Error(Common_variable.PRICEERROR);
      } else {
        setStateError(false);
        setState_Error("");
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} style={{ paddingTop: "10px!important" }}>
            <Card>
              <Row style={{ paddingTop: "10px!important" }}>
                <Col xs={8}></Col>
                <Col xs={3} pt={3}>
                  <Button
                    color="primary"
                    style={{ float: "right", marginTop: "15px" }}
                    onClick={() => addSponsor("", "add")}
                  >
                    Add
                  </Button>
                </Col>
                <Col xs={1}></Col>
              </Row>
              <MDBox pt={3}>
                <DataTable
                  // pagination
                  sortServer
                  // paginationServer
                  noDataComponent="There are no records to display"
                  subHeader={true}
                  columns={columns}
                  responsive={true}
                  onSort={handleSort}
                  data={sponsorShipList}
                  sortIcon={<ChevronDown />}
                  className="react-dataTable"
                  defaultSortField="invoiceId"
                  // paginationDefaultPage={currentPage}
                  // paginationComponent={CustomPagination}
                  // icons={tableIcons}
                />
                {/* <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First</th>
                      <th scope="col">Last</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Larry</td>
                      <td>the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </table> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        isOpen={sponsorOpen}
        className="vertically-centered-modal"
        fade={false}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
      >
        {/* toggle={() => setSponsorOpen(false)} */}
        <ModalHeader color="primary">Add Sponsor</ModalHeader>
        <ModalBody>
          <div className="scrollview">
            <Card style={{ marginBottom: "10px" }}>
              <Row>
                <Col xl="12">
                  {/* {sponsorJson && sponsorJson.stallname ? ( */}
                  {/* const [companyNameDisabled, setCompanyNameDisabled] = useState(false);
  const [contactPersonNameDisabled, setContactPersonNameDisabled] = useState(false);
  const [mobileNumberDisabled, setMobileNumberDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(false);
  const [companyAddressDisabled, setCompanyAddressDisabled] = useState(false);
  const [sponsorshipDisabled, setSponsorshipDisabled] = useState(false);
  const [priceDisabled, setPriceDisabled] = useState(false); */}
                  <Grid container spacing={2}>
                    <Grid className="paddingtop-0 margin-top-8" item xs={12} sm={12} md={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="CompanyName"
                        label="Company Name"
                        type="text"
                        id="CompanyName"
                        value={companyName}
                        autoFocus
                        onChange={(e) => onChangeEvent(e, "companyname")}
                        inputRef={textcompanyNameRef}
                        variant="outlined"
                        disabled={companyNameDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {companyNameError === true ? companyName_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="ContactPerson"
                        label="Contact Person Name"
                        type="text"
                        id="contactPersonName"
                        value={contactPersonName}
                        inputRef={textcontactPersonNameRef}
                        onChange={(e) => {
                          onChangeEvent(e, "contactpersonname");
                        }}
                        variant="outlined"
                        disabled={contactPersonNameDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {contactPersonNameError === true ? contactPersonName_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="MobileNo"
                        label="Mobile Number"
                        type="text"
                        id="MobileNumber"
                        value={mobileNumber}
                        inputRef={textMobileNumberRef}
                        onChange={(e) => onChangeEvent(e, "mobileno")}
                        onKeyPress={(event) => numberOnly(event)}
                        onPaste={(e) => numberOnly(e)}
                        inputProps={{ maxLength: 10 }}
                        variant="outlined"
                        disabled={mobileNumberDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {mobileError === true ? mobileNumberError : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <TextField
                        multiline
                        rows={3}
                        margin="normal"
                        required
                        fullWidth
                        name="CompanyAddress"
                        label="Company Address"
                        type="text"
                        id="companyAddress"
                        value={companyAddress}
                        inputRef={textcompanyAddressRef}
                        onChange={(e) => onChangeEvent(e, "companyaddress")}
                        variant="outlined"
                        disabled={companyAddressDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {companyAddressError === true ? companyAddress_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="city"
                        label="City"
                        type="text"
                        id="City"
                        value={city}
                        inputRef={textCityRef}
                        onChange={(e) => {
                          onChangeEvent(e, "city");
                        }}
                        variant="outlined"
                        disabled={cityDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {cityError === true ? city_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="State"
                        label="State"
                        type="text"
                        id="State"
                        value={state}
                        inputRef={textStateRef}
                        onChange={(e) => {
                          onChangeEvent(e, "state");
                        }}
                        variant="outlined"
                        disabled={stateDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {stateError === true ? state_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <Label className="form-label">Sponsorship</Label>
                      {/* <Select
                        defaultValue={BindSponsorshipId}
                        onChange={(e) => itemOnchange(e)}
                        options={BindSponsorshipCatList}
                        onKeyDown={onEnterKeyPressed}
                        inputRef={sponsorshipRef}
                      /> */}
                      {/* <Select
                        isClearable={true}
                        hideSelectedOptions={false}
                        // isMulti={true}
                        className="react-select"
                        options={BindSponsorshipCatList}
                        value={BindSponsorshipId}
                        onChange={(e) => itemOnchange(e)}
                      /> */}
                      {/* <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Sponsorship
                        </InputLabel>
                        <NativeSelect
                          defaultValue={BindSponsorshipId}
                          onChange={(e) => itemOnchange(e)}
                          input={<BootstrapInput />}
                          label="Age *"
                          inputProps={{
                            name: "Sponsorship",
                            id: "uncontrolled-native",
                          }}
                        >
                          {empRecords}
                        </NativeSelect>
                      </FormControl> */}
                      <Select
                        // isClearable={true}
                        hideSelectedOptions={false}
                        className="react-select"
                        options={BindSponsorshipCatList}
                        value={BindSponsorshipId}
                        onChange={(e) => itemOnchange(e)}
                        isDisabled={sponsorshipDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {sponsorError === true ? sponsor_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                    <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="Price"
                        label="Price"
                        type="text"
                        id="Price"
                        value={price}
                        inputRef={textpriceRef}
                        onChange={(e) => onChangeEvent(e, "price")}
                        onKeyPress={(event) => numberOnly(event)}
                        onKeyDown={onEnterKeyPressed}
                        onPaste={(e) => numberOnly(e)}
                        inputProps={{ maxLength: 10 }}
                        variant="outlined"
                        disabled={priceDisabled}
                      />
                      <Box component="form" sx={{ mt: 1 }}>
                        <span className="invalid-feedback">
                          {priceError === true ? price_Error : ""}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                </Col>
              </Row>
            </Card>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => beforeSaveSponsor()} disabled={btnBookDisabled}>
            {btnTitle}
          </Button>{" "}
          {/* {sponsorID == 0 ? (
            <Button color="primary" onClick={() => beforeSaveSponsor()} disabled={btnBookDisabled}>
              Book
            </Button>
          ) : (
            ""
          )}{" "} */}
          {/* <Button color="primary" onClick={() => beforeBooking()} disabled={btnBookDisabled}>
            Book
          </Button>{" "} */}
          {/* {sponsorID != 0 && usereditoption && usereditoption == true ? (
            <Button color="primary" onClick={() => beforeSaveSponsor()} disabled={btnBookDisabled}>
              Update
            </Button>
          ) : (
            ""
          )}{" "} */}
          <Button
            color="primary"
            outline
            onClick={() => {
              setSponsorOpen(false);
              localStorage.removeItem("btnDisabled");
            }}
            // disabled={btnCloseDisabled}
          >
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Toaster position={"top-right"} toastOptions={{ className: "react-hot-toast" }} />
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Sponsor;
