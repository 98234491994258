export const api = {
  //Local Url
  // api_url: "http://localhost:8011/api",
  api_url: "https://stallbookingapi.trio-s.com/api",
};
export const Common_variable = {
  Regax_validation: /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/,
  characters_pattern: /^[a-zA-Z0-9]*$/,
  numbercharacters_pattern: /^[a-zA-Z0-9-@._ ]*$/,
  numberpattern: /^[0-9]*$/,
  numberdotpattern: /^[0-9.-]*$/,
  specialcharacters_pattern: /^[a-zA-Z-._ ]*$/,
  onlycharacters: /^[a-zA-Z0-9-_]*$/,
  itemname_pattern: /^[a-zA-Z0-9-._ ]*$/,
  STATUSLIST: [
    { label: "All", value: 0 },
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ],
  MOBILENOERROR: "Please enter mobile number",
  OTPERROR: "Please enter OTP",
  MOBILENOLENGTHERROR: "Please enter valid mobile number",
  OTPLENGTHERROR: "Please enter valid OTP",
  COMPANYNAMEERROR: "Please enter company name",
  CONTACTPERSONNAMEERROR: "Please enter contact person name",
  COMPANYADDRESSERROR: "Please enter company address",
  CITYERROR: "Please enter city",
  STATEERROR: "Please enter state",
  SPONSORSHIPERROR: "Please select sponsorship",
  PRICEERROR: "Please enter price",
};
