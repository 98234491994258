/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { getUserData } from "../../auth/utils";
import { useState, useEffect, useRef } from "react";
import * as React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import {} from "assets/css/style.css";
import axios from "axios";
import { ORDERTAKING, Common_variable, BOXING, api } from "../../utility/constants";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useMaterialUIController, setLayout } from "context";
// import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import {
  Eye,
  Send,
  Edit,
  Copy,
  Save,
  Info,
  Trash,
  PieChart,
  Download,
  TrendingUp,
  CheckCircle,
  MoreVertical,
  ArrowDownCircle,
  ChevronDown,
  Home,
} from "react-feather";
// ** Reactstrap Imports
import {
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  FormFeedback,
  Button,
} from "reactstrap";
// import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function Dashboard() {
  // const user = getUserData();
  const navigate = useNavigate();
  const { sales, tasks } = reportsLineChartData;
  const [userid, setUserID] = useState(0);
  const [usereditoption, setUserEditOption] = useState(false);
  const [StallBookingStatus, setStallBookingStatus] = useState("Success");
  const [StallBookingList, setStallBookingLists] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const [StallBookingJson, setStallBookingJson] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [stallId, setStallId] = useState(0);
  const [bookingId, setBookingId] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyName_Error, setCompanyName_Error] = useState("");
  const [contactPersonNameError, setContactPersonNameError] = useState(false);
  const [contactPersonName_Error, setContactPersonName_Error] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [cityError, setCityError] = useState(false);
  const [city_Error, setCity_Error] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState(false);
  const [companyAddress_Error, setCompanyAddress_Error] = useState("");
  const [stateError, setStateError] = useState(false);
  const [state_Error, setState_Error] = useState("");
  const [btnBookDisabled, setBtnBookDisabled] = useState(false);
  const [btnCloseDisabled, setBtnCloseDisabled] = useState(false);
  const [btnCancelDisabled, setBtnCancelDisabled] = useState(false);
  const [btnConfirmYesDisabled, setBtnConfirmYesDisabled] = useState(false);
  const [btnConfirmNoDisabled, setBtnConfirmNoDisabled] = useState(false);
  const [companyNameDisabled, setCompanyNameDisabled] = useState(false);
  const [contactPersonNameDisabled, setContactPersonNameDisabled] = useState(false);
  const [mobileNumberDisabled, setMobileNumberDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(false);
  const [companyAddressDisabled, setCompanyAddressDisabled] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [c_total1, setC_Total1] = useState(0);
  const [c_total2, setC_Total2] = useState(0);
  const [c_total3, setC_Total3] = useState(0);
  const [c_total4, setC_Total4] = useState(0);
  const [c_total5, setC_Total5] = useState(0);
  const [btnTitle, setBtnTitle] = useState("Book");
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const refCompanyname = useRef(null);
  const textcompanyNameRef = useRef(null);
  const textcontactPersonNameRef = useRef(null);
  const textMobileNumberRef = useRef(null);
  const textcompanyAddressRef = useRef(null);
  const textCityRef = useRef(null);
  const textStateRef = useRef(null);
  const itemkey = "stall";
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const getStallBookingList = async () => {
    const params = { userid: Number(userid) };
    const response = await axios.post(`${api.api_url}/stallbookingRoutes/getstalllistJwt`, params);
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(`${api.api_url}/stallbookingRoutes/getstalllist`, param);
      if (response1 && response1.data && response1.data.body) {
        const stallresponse = response1.data.body;
        const stallListData = stallresponse.stallArray ? stallresponse.stallArray : [];
        setStallBookingLists(stallListData);
        // var array = stallListData.map((e) => {
        //   e.status = "Pending";
        //   return e;
        // });
        var t1 = 0;
        var t2 = 0;
        var t3 = 0;
        var t4 = 0;
        var t5 = 0;
        stallListData.map((t) => {
          if (t.categoryno == 1 && t.status == "Booked") {
            t1 = t1 + 1;
          }
          if (t.categoryno == 2 && t.status == "Booked") {
            t2 = t2 + 1;
          }
          if (t.categoryno == 3 && t.status == "Booked") {
            t3 = t3 + 1;
          }
          if (t.categoryno == 4 && t.status == "Booked") {
            t4 = t4 + 1;
          }
          if (t.categoryno == 5 && t.status == "Booked") {
            t5 = t5 + 1;
          }
          setC_Total1(t1);
          setC_Total2(t2);
          setC_Total3(t3);
          setC_Total4(t4);
          setC_Total5(t5);
        });
        setBtnTitle("Book");
      }
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const clearError = () => {
    setCompanyNameError(false);
    setCompanyName_Error("");
    setContactPersonNameError(false);
    setContactPersonName_Error("");
    setMobileError(false);
    setMobileNumberError("");
    setCompanyAddressError(false);
    setCompanyAddress_Error("");
    setCityError(false);
    setCity_Error("");
    setStateError(false);
    setState_Error("");
  };
  const clearPopupData = () => {
    setCompanyName("");
    setContactPersonName("");
    setMobileNumber("");
    setCompanyAddress("");
    setCity("");
    setState("");
    setBookingId(0);
    setBtnBookDisabled(false);
    setBtnCloseDisabled(false);
    setBtnCancelDisabled(false);
  };
  const StallOnClick = (t) => {
    localStorage.setItem("btnDisabled", "true");
    setStallBookingJson({});
    clearError();
    if (t.status == "Pending") {
      clearPopupData();
      setStallId(t.stallid);
      setBtnTitle("Book");
      setCompanyNameDisabled(false);
      setContactPersonNameDisabled(false);
      setMobileNumberDisabled(false);
      setStateDisabled(false);
      setCityDisabled(false);
      setCompanyAddressDisabled(false);
      setBtnCancelDisabled(false);
      setBtnBookDisabled(false);
    } else {
      setCompanyName(t.companyname);
      setContactPersonName(t.contactpersonname);
      setMobileNumber(t.mobilenumber);
      setCompanyAddress(t.companyaddress);
      setCity(t.cityname);
      setState(t.statename);
      setStallId(t.stallid);
      setBookingId(t.bookingid);
      setBtnTitle("Update");
      if (usereditoption == false) {
        setCompanyNameDisabled(true);
        setContactPersonNameDisabled(true);
        setMobileNumberDisabled(true);
        setStateDisabled(true);
        setCityDisabled(true);
        setCompanyAddressDisabled(true);
        setBtnCancelDisabled(true);
        setBtnBookDisabled(true);
      }
    }
    setStallBookingJson(t);
    setOpen(true);
  };
  useEffect(() => {
    setBookingId(0);
    var user_id = localStorage.getItem("userCode");
    if (
      user_id == "" ||
      user_id == undefined ||
      user_id == null ||
      user_id == "undefined" ||
      user_id == "null"
    ) {
      navigate("/login");
    }
    setUserID(user_id);
    var editaccess = localStorage.getItem("edit_access");
    if (
      editaccess == "" ||
      editaccess == undefined ||
      editaccess == null ||
      editaccess == "undefined" ||
      editaccess == "null" ||
      editaccess == "no"
    ) {
      setUserEditOption(false);
    } else {
      setUserEditOption(true);
    }
    setOpen(false);
    localStorage.removeItem("btnDisabled");
    setConfirmOpen(false);
    setStallBookingLists([]);
    getStallBookingList();
  }, []);
  useEffect(() => {
    setLayout(dispatch, "stallList");
    setOpen(false);
    localStorage.removeItem("btnDisabled");
  }, [pathname]);
  const numberOnly = (event) => {
    const regex = /^[0-9]*$/;
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const stallBooking = async () => {
    const params = {
      stallId: stallId,
      companyName: companyName,
      contactPersonName: contactPersonName,
      mobileNumber: mobileNumber,
      companyAddress: companyAddress,
      city: city,
      state: state,
      bookingId: bookingId,
      userid: Number(userid),
    };
    const response = await axios.post(`${api.api_url}/stallbookingRoutes/stallBookingJwt`, params);
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(`${api.api_url}/stallbookingRoutes/stallBooking`, param);
      if (response1 && response1.data && response1.data.body) {
        const stallBookingResponse = response1.data.body;
        if (stallBookingResponse && stallBookingResponse.statusFlag === 1) {
          toast.success(stallBookingResponse.message, {
            duration: 2000,
            className: "toaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#2e7d32" },
          });
          setOpen(false);
          localStorage.removeItem("btnDisabled");
          clearError();
          setBtnTitle("Book");
          setStallBookingJson({});
          clearPopupData();
          setStallBookingLists([]);
          getStallBookingList();
        } else {
          toast.error(stallBookingResponse.message, {
            duration: 2000,
            className: "waringtoaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#ed6c02" },
          });
          setBtnBookDisabled(false);
          setBtnCloseDisabled(false);
          setBtnCancelDisabled(false);
        }
      }
    }
  };
  const beforeBooking = async () => {
    if (companyName == "" || companyName == undefined) {
      setCompanyNameError(true);
      setCompanyName_Error(Common_variable.COMPANYNAMEERROR);
      if (textcompanyNameRef.current) {
        textcompanyNameRef.current.focus();
      }
    } else if (contactPersonName == "" || contactPersonName == undefined) {
      setContactPersonNameError(true);
      setContactPersonName_Error(Common_variable.CONTACTPERSONNAMEERROR);
      if (textcontactPersonNameRef.current) {
        textcontactPersonNameRef.current.focus();
      }
    } else if (mobileNumber == "" || mobileNumber == undefined) {
      setMobileError(true);
      setMobileNumberError(Common_variable.MOBILENOERROR);
      if (textMobileNumberRef.current) {
        textMobileNumberRef.current.focus();
      }
    } else if (companyAddress == "" || companyAddress == undefined) {
      setCompanyAddressError(true);
      setCompanyAddress_Error(Common_variable.COMPANYADDRESSERROR);
      if (textcompanyAddressRef.current) {
        textcompanyAddressRef.current.focus();
      }
    } else if (city == "" || city == undefined) {
      setCityError(true);
      setCity_Error(Common_variable.CITYERROR);
      if (textCityRef.current) {
        textCityRef.current.focus();
      }
    } else if (state == "" || state == undefined) {
      setStateError(true);
      setState_Error(Common_variable.STATEERROR);
      if (textStateRef.current) {
        textStateRef.current.focus();
      }
    } else {
      setBtnBookDisabled(true);
      setBtnCloseDisabled(true);
      setBtnCancelDisabled(true);
      clearError();
      await stallBooking();
    }
  };
  const onChangeEvent = (e, from) => {
    if (from == "companyname") {
      setCompanyName(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setCompanyNameError(true);
        setCompanyName_Error(Common_variable.COMPANYNAMEERROR);
      } else {
        setCompanyNameError(false);
        setCompanyName_Error("");
      }
    } else if (from == "contactpersonname") {
      var ch = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setContactPersonName(ch);
      if (e.target.value == "" || e.target.value == undefined) {
        setContactPersonNameError(true);
        setContactPersonName_Error(Common_variable.CONTACTPERSONNAMEERROR);
      } else {
        setContactPersonNameError(false);
        setContactPersonName_Error("");
      }
    } else if (from == "mobileno") {
      setMobileNumber(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setMobileError(true);
        setMobileNumberError(Common_variable.MOBILENOERROR);
      } else {
        if (e.target.value.length < 10) {
          setMobileError(true);
          setMobileNumberError(Common_variable.MOBILENOLENGTHERROR);
        } else {
          setMobileError(false);
          setMobileNumberError("");
        }
      }
    } else if (from == "companyaddress") {
      setCompanyAddress(e.target.value);
      if (e.target.value == "" || e.target.value == undefined) {
        setCompanyAddressError(true);
        setCompanyAddress_Error(Common_variable.COMPANYADDRESSERROR);
      } else {
        setCompanyAddressError(false);
        setCompanyAddress_Error("");
      }
    } else if (from == "city") {
      var ch = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setCity(ch);
      if (e.target.value == "" || e.target.value == undefined) {
        setCityError(true);
        setCity_Error(Common_variable.CITYERROR);
      } else {
        setCityError(false);
        setCity_Error("");
      }
    } else if (from == "state") {
      var ch = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setState(ch);
      if (e.target.value == "" || e.target.value == undefined) {
        setStateError(true);
        setState_Error(Common_variable.STATEERROR);
      } else {
        setStateError(false);
        setState_Error("");
      }
    }
  };
  const beforeCancel = () => {
    setBtnConfirmYesDisabled(false);
    setBtnConfirmNoDisabled(false);
    setConfirmOpen(true);
    setBtnBookDisabled(false);
    setBtnCloseDisabled(false);
    setBtnCancelDisabled(false);
  };
  const CancelBooking = async () => {
    setBtnConfirmYesDisabled(true);
    setBtnConfirmNoDisabled(true);
    const params = {
      stallId: stallId,
      bookingId: bookingId,
      userid: Number(userid),
    };
    const response = await axios.post(
      `${api.api_url}/stallbookingRoutes/cancelStallBookingJwt`,
      params
    );
    if (response && response.data && response.data.body && response.data.body.token) {
      const param = { jwtToken: response.data.body.token };
      const response1 = await axios.post(
        `${api.api_url}/stallbookingRoutes/cancelStallBooking`,
        param
      );
      if (response1 && response1.data && response1.data.body) {
        const stallCancelResponse = response1.data.body;
        if (stallCancelResponse && stallCancelResponse.statusFlag === 1) {
          toast.success(stallCancelResponse.message, {
            duration: 2000,
            className: "toaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#2e7d32" },
          });
          setOpen(false);
          localStorage.removeItem("btnDisabled");
          clearError();
          setBtnTitle("Book");
          setStallBookingJson({});
          clearPopupData();
          setStallBookingLists([]);
          getStallBookingList();
        } else {
          toast.error(stallCancelResponse.message, {
            duration: 2000,
            className: "waringtoaststyle",
            iconTheme: { primary: "#FFFAEE", secondary: "#ed6c02" },
          });
          setBtnBookDisabled(false);
          setBtnCloseDisabled(false);
          setBtnCancelDisabled(false);
        }
        setBtnConfirmYesDisabled(false);
        setBtnConfirmNoDisabled(false);
        setConfirmOpen(false);
      }
    }
  };
  const onEnterKeyPressed = (e) => {
    if (e.key === "Enter") {
      if (usereditoption == true) {
        beforeBooking();
      }
    }
  };
  const handleChangeAccordion = (from) => {
    setExpanded(from);
  };
  return (
    <DashboardLayout>
      <div className="card w-100" style={{ padding: "20px" }}>
        <DashboardNavbar />
        <Accordion
          style={{ background: "#fefed9" }}
          // expanded={expanded === "panel1"}
          // onChange={handleChangeAccordion("panel1")}
        >
          <AccordionSummary
            // expandIcon={
            //   expanded === "panel1" ? (
            //     <span className="badge badge-secondary badge-pill">8</span>
            //   ) : (
            //     <ExpandMoreIcon />
            //   )
            // }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              6m × 6m Stalls
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total&nbsp;-&nbsp;8
              &nbsp;nos
              {/* <span className="badge badge-secondary badge-pill">8 nos</span> */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Booked&nbsp;
              -&nbsp;
              {c_total1}
              &nbsp;nos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox py={3}>
              <Grid container spacing={4}>
                {StallBookingList &&
                  StallBookingList.length > 0 &&
                  StallBookingList.map((t, index) => {
                    if (t.categoryno == 1) {
                      // <div >
                      return (
                        <Grid
                          className="cardfocus"
                          item
                          xs={6}
                          md={3}
                          lg={2}
                          key={itemkey + index}
                          onClick={() => StallOnClick(t)}
                        >
                          <div>
                            <MDBox mb={1.5} className="category1">
                              <DefaultInfoCard
                                icon="store"
                                color={t.status == "Booked" ? "warning" : "success"}
                                title={t.stallname}
                                description={t.companyname ? t.companyname : ""}
                                description1={t.mobilenumber ? t.mobilenumber : ""}
                                value=""
                              />
                            </MDBox>
                          </div>
                        </Grid>
                      );
                      // </div>
                    }
                  })}
              </Grid>
            </MDBox>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#d9e8da" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              27sqm Stalls
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total&nbsp;-&nbsp;4&nbsp;
              nos
              {/* <span className="badge badge-secondary badge-pill">4 nos</span> */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Booked
              &nbsp;-&nbsp;
              {c_total2}
              &nbsp;nos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox py={3}>
              <Grid container spacing={4}>
                {StallBookingList &&
                  StallBookingList.length > 0 &&
                  StallBookingList.map((t, index) => {
                    if (t.categoryno == 2) {
                      // <div >
                      return (
                        <Grid
                          className="cardfocus"
                          item
                          xs={6}
                          md={3}
                          lg={2}
                          key={itemkey + index}
                          onClick={() => StallOnClick(t)}
                        >
                          <div>
                            <MDBox mb={1.5}>
                              <DefaultInfoCard
                                icon="store"
                                color={t.status == "Booked" ? "warning" : "success"}
                                title={t.stallname}
                                description={t.companyname ? t.companyname : ""}
                                description1={t.mobilenumber ? t.mobilenumber : ""}
                                value=""
                              />
                            </MDBox>
                          </div>
                        </Grid>
                      );
                      // </div>
                    }
                  })}
              </Grid>
            </MDBox>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#fcd7cd" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              3m × 3m Stalls
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total -
              20 nos
              {/* <span className="badge badge-secondary badge-pill">20 nos</span> */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Booked
              &nbsp;-&nbsp;
              {c_total3}&nbsp;nos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox py={3}>
              <Grid container spacing={4}>
                {StallBookingList &&
                  StallBookingList.length > 0 &&
                  StallBookingList.map((t, index) => {
                    if (t.categoryno == 3) {
                      // <div >
                      return (
                        <Grid
                          className="cardfocus"
                          item
                          xs={6}
                          md={3}
                          lg={2}
                          key={itemkey + index}
                          onClick={() => StallOnClick(t)}
                        >
                          <div>
                            <MDBox mb={1.5}>
                              <DefaultInfoCard
                                icon="store"
                                color={t.status == "Booked" ? "warning" : "success"}
                                title={t.stallname}
                                description={t.companyname ? t.companyname : ""}
                                description1={t.mobilenumber ? t.mobilenumber : ""}
                                value=""
                              />
                            </MDBox>
                          </div>
                        </Grid>
                      );
                      // </div>
                    }
                  })}
              </Grid>
            </MDBox>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#d8a3ca" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              3m × 3m Stalls
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total -
              32 nos
              {/* <span className="badge badge-secondary badge-pill">32 nos</span> */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Booked
              &nbsp;-&nbsp;
              {c_total4}&nbsp;nos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox py={3}>
              <Grid container spacing={4}>
                {StallBookingList &&
                  StallBookingList.length > 0 &&
                  StallBookingList.map((t, index) => {
                    if (t.categoryno == 4) {
                      // <div >
                      return (
                        <Grid
                          className="cardfocus"
                          item
                          xs={6}
                          md={3}
                          lg={2}
                          key={itemkey + index}
                          onClick={() => StallOnClick(t)}
                        >
                          <div>
                            <MDBox mb={1.5}>
                              <DefaultInfoCard
                                icon="store"
                                color={t.status == "Booked" ? "warning" : "success"}
                                title={t.stallname}
                                description={t.companyname ? t.companyname : ""}
                                description1={t.mobilenumber ? t.mobilenumber : ""}
                                value=""
                              />
                            </MDBox>
                          </div>
                        </Grid>
                      );
                      // </div>
                    }
                  })}
              </Grid>
            </MDBox>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ background: "#cfcfe6" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              3m × 3m Stalls
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total -
              31 nos
              {/* <span className="badge badge-secondary badge-pill">31 nos</span> */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Booked
              &nbsp;-&nbsp;
              {c_total5}&nbsp;nos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox py={3}>
              <Grid container spacing={4}>
                {StallBookingList &&
                  StallBookingList.length > 0 &&
                  StallBookingList.map((t, index) => {
                    if (t.categoryno == 5) {
                      // <div >
                      return (
                        <Grid
                          className="cardfocus"
                          item
                          xs={6}
                          md={3}
                          lg={2}
                          key={itemkey + index}
                          onClick={() => StallOnClick(t)}
                        >
                          <div>
                            <MDBox mb={1.5}>
                              <DefaultInfoCard
                                icon="store"
                                color={t.status == "Booked" ? "warning" : "success"}
                                title={t.stallname}
                                description={t.companyname ? t.companyname : ""}
                                description1={t.mobilenumber ? t.mobilenumber : ""}
                                value=""
                              />
                            </MDBox>
                          </div>
                        </Grid>
                      );
                      // </div>
                    }
                  })}
              </Grid>
            </MDBox>
          </AccordionDetails>
        </Accordion>
        <Modal
          isOpen={open}
          className="vertically-centered-modal"
          fade={false}
          aria-labelledby="contained-modal-title-vcenter"
          // centered
        >
          {/* toggle={() => setOpen(false)} */}
          <ModalHeader color="primary">
            <div className="title">Stall Booking</div>
            {/* <span>{"#".StallBookingJson.stallname}</span> */}
            <div className="stallname">
              <Home size={14} className="me-50 cursor-focus margin-bottom-4" />
              &nbsp;{StallBookingJson.stallname}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="scrollview">
              <Card style={{ marginBottom: "10px" }}>
                <Row>
                  <Col xl="12">
                    {StallBookingJson && StallBookingJson.stallname ? (
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={12} md={12}>                         
                          <Tab
                            className="noHover"
                            label={StallBookingJson.stallname}
                            icon={
                              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                home
                              </Icon>
                            }
                          />
                        </Grid> */}
                        <Grid className="paddingtop-0 margin-top-8" item xs={12} sm={12} md={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="CompanyName"
                            label="Company Name"
                            type="text"
                            id="CompanyName"
                            value={companyName}
                            autoFocus
                            onChange={(e) => onChangeEvent(e, "companyname")}
                            inputRef={textcompanyNameRef}
                            disabled={companyNameDisabled}
                            variant="outlined"
                          />
                          <Box component="form" sx={{ mt: 1 }}>
                            <span className="invalid-feedback">
                              {companyNameError === true ? companyName_Error : ""}
                            </span>
                          </Box>
                        </Grid>
                        <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="ContactPerson"
                            label="Contact Person Name"
                            type="text"
                            id="contactPersonName"
                            value={contactPersonName}
                            inputRef={textcontactPersonNameRef}
                            disabled={contactPersonNameDisabled}
                            onChange={(e) => {
                              onChangeEvent(e, "contactpersonname");
                            }}
                            variant="outlined"
                          />
                          <Box component="form" sx={{ mt: 1 }}>
                            <span className="invalid-feedback">
                              {contactPersonNameError === true ? contactPersonName_Error : ""}
                            </span>
                          </Box>
                        </Grid>
                        <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="MobileNo"
                            label="Mobile Number"
                            type="text"
                            id="MobileNumber"
                            value={mobileNumber}
                            inputRef={textMobileNumberRef}
                            onChange={(e) => onChangeEvent(e, "mobileno")}
                            onKeyPress={(event) => numberOnly(event)}
                            onPaste={(e) => numberOnly(e)}
                            inputProps={{ maxLength: 10 }}
                            disabled={mobileNumberDisabled}
                            variant="outlined"
                          />
                          <Box component="form" sx={{ mt: 1 }}>
                            <span className="invalid-feedback">
                              {mobileError === true ? mobileNumberError : ""}
                            </span>
                          </Box>
                        </Grid>
                        <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                          <TextField
                            multiline
                            rows={3}
                            margin="normal"
                            required
                            fullWidth
                            name="CompanyAddress"
                            label="Company Address"
                            type="text"
                            id="companyAddress"
                            value={companyAddress}
                            inputRef={textcompanyAddressRef}
                            onChange={(e) => onChangeEvent(e, "companyaddress")}
                            disabled={companyAddressDisabled}
                            variant="outlined"
                          />
                          <Box component="form" sx={{ mt: 1 }}>
                            <span className="invalid-feedback">
                              {companyAddressError === true ? companyAddress_Error : ""}
                            </span>
                          </Box>
                        </Grid>
                        <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="city"
                            label="City"
                            type="text"
                            id="City"
                            value={city}
                            inputRef={textCityRef}
                            onChange={(e) => {
                              onChangeEvent(e, "city");
                            }}
                            disabled={cityDisabled}
                            variant="outlined"
                          />
                          <Box component="form" sx={{ mt: 1 }}>
                            <span className="invalid-feedback">
                              {cityError === true ? city_Error : ""}
                            </span>
                          </Box>
                        </Grid>
                        <Grid className="paddingtop-0" item xs={12} sm={12} md={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="State"
                            label="State"
                            type="text"
                            id="State"
                            value={state}
                            inputRef={textStateRef}
                            onChange={(e) => {
                              onChangeEvent(e, "state");
                            }}
                            onKeyDown={onEnterKeyPressed}
                            disabled={stateDisabled}
                            variant="outlined"
                          />
                          <Box component="form" sx={{ mt: 1 }}>
                            <span className="invalid-feedback">
                              {stateError === true ? state_Error : ""}
                            </span>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Card>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* {bookingId == 0 ? (
              <Button color="primary" onClick={() => beforeBooking()} disabled={btnBookDisabled}>
                Book
              </Button>
            ) : (
              ""
            )}{" "} */}
            {/* <Button color="primary" onClick={() => beforeBooking()} disabled={btnBookDisabled}>
              Book
            </Button>{" "} */}
            <Button color="primary" onClick={() => beforeBooking()} disabled={btnBookDisabled}>
              {btnTitle}
            </Button>
            {/*  && usereditoption && usereditoption == true */}
            {bookingId != 0 ? (
              <Button color="danger" onClick={() => beforeCancel()} disabled={btnCancelDisabled}>
                Cancel
              </Button>
            ) : (
              ""
            )}{" "}
            <Button
              color="primary"
              outline
              onClick={() => {
                setOpen(false);
                localStorage.removeItem("btnDisabled");
              }}
              // disabled={btnCloseDisabled}
            >
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={confirmOpen}
          className="vertically-centered-modal"
          fade={false}
          aria-labelledby="contained-modal-title-vcenter"
          // centered
        >
          {/* toggle={() => setOpen(false)} */}
          <ModalHeader color="primary">Confirmation</ModalHeader>
          <ModalBody>
            <div className="scrollview">
              <Card style={{ marginBottom: "10px" }}>
                <Row>
                  <Col xl="12">
                    <p>Are you sure you want to cancel this stall booking?</p>
                  </Col>
                </Row>
              </Card>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => CancelBooking()}
              disabled={btnConfirmYesDisabled}
            >
              Yes
            </Button>{" "}
            <Button
              color="danger"
              outline
              onClick={() => setConfirmOpen(false)}
              disabled={btnConfirmNoDisabled}
            >
              No
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Toaster position={"top-right"} toastOptions={{ className: "react-hot-toast" }} />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
